import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT Server @Homey",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/",
  "dateChanged": "2019-08-20",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/P_SearchThumb_Homey.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Homey"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='INSTAR MQTT Server @Homey' dateChanged='2019-08-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/P_SearchThumb_Homey.png' twitter='/images/Search/P_SearchThumb_Homey.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/Athom_Homey_MQTT_Client/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-mqtt-server-homey",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-server-homey",
        "aria-label": "instar mqtt server homey permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Server @Homey`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-the-homey-mqtt-client"
        }}>{`Adding the Homey MQTT Client`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sending-a-mqtt-command-to-your-camera"
        }}>{`Sending a MQTT Command to your Camera`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`In this tutorial we want to connect an INSTAR Full HD camera through it's MQTT Interface with the `}<a parentName="p" {...{
        "href": "https://www.athom.com/en/"
      }}>{`Athom Homey Smarthome`}</a>{`.`}</p>
    <h2 {...{
      "id": "adding-the-homey-mqtt-client",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-the-homey-mqtt-client",
        "aria-label": "adding the homey mqtt client permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the Homey MQTT Client`}</h2>
    <p>{`Homey is a modular - that means that it is only set up with a limited amount of functionality out of the box. You can use the Homey App Store to load all kinds of kinds apps onto your gateway to personalize it's functionality. For the following we are going to need an MQTT client to be able to connect to the MQTT server on our camera.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cfbd0d8894204b533e95977d478d387b/aa440/INSTAR_MQTT_with_Athom_Homey_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACFUlEQVQoz1XPS0tUYQDG8fMFoiJnzjnveW/nnJk5jqMTQSNqYmEYaSUqeClJ7aKWdDHI7i3aBC0qwgzFRLPMiMSFQYvctGjfqq/zL0cdavFsfzx/p2Vgidaedbo6vzJ0epOJkz94cuo3gw338MQ+ZCBIsoZMpPDcFEr6FBJLHGqM9AmkT164PNizl9FCFU79yCqlgS80da3T3vmNvo5NJjp+0XV4Cuntx3iCYnVCPo4IUmlkKkVsApQ2KKWRUiGl5IArqc+ncfJDSxTOrnCw9xMN3Wuc6Nyg/8xPeo7dJ2d9QiExvo/xvPKs65KECmMMWmuUUmglSXmShoKLk4wtUD2yTGHwA8W+VUrdn2nr/k5b6xRRrAikJpASEQT4QuD5PplIY62toFor0p6iseDhJDfmyY0vUnPxHXXnVyj2f6TUu0Gp+SpKuuhy2nZWEAQIIchugWFYAc0W6Gsaaz2c7NQs2ZsL5CcWqR1bpm74PcVzaxRbRjHKwxhbSdtFc7EhDMPKS2s0rtA01fk4/p1XiMk36GtzhONvyV5YIHtpjULrZazy/3uyiyYZSxRFhNaWUWsNnjDbYNWjF6TvTiNuzaCvz2GvzHP03DSHjg8TGokNozL4L5pkd8Cdl+EWGFiOFAWOePqcqscvUQ9fo27PYCZnaRt6Rn37MDXVGZIkIZfLEkch0c7yuZA4jitoFFp8aWn+C/4BZfIoTxCteEEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cfbd0d8894204b533e95977d478d387b/e4706/INSTAR_MQTT_with_Athom_Homey_01.avif 230w", "/en/static/cfbd0d8894204b533e95977d478d387b/d1af7/INSTAR_MQTT_with_Athom_Homey_01.avif 460w", "/en/static/cfbd0d8894204b533e95977d478d387b/7f308/INSTAR_MQTT_with_Athom_Homey_01.avif 920w", "/en/static/cfbd0d8894204b533e95977d478d387b/e1c99/INSTAR_MQTT_with_Athom_Homey_01.avif 1380w", "/en/static/cfbd0d8894204b533e95977d478d387b/2b305/INSTAR_MQTT_with_Athom_Homey_01.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cfbd0d8894204b533e95977d478d387b/a0b58/INSTAR_MQTT_with_Athom_Homey_01.webp 230w", "/en/static/cfbd0d8894204b533e95977d478d387b/bc10c/INSTAR_MQTT_with_Athom_Homey_01.webp 460w", "/en/static/cfbd0d8894204b533e95977d478d387b/966d8/INSTAR_MQTT_with_Athom_Homey_01.webp 920w", "/en/static/cfbd0d8894204b533e95977d478d387b/445df/INSTAR_MQTT_with_Athom_Homey_01.webp 1380w", "/en/static/cfbd0d8894204b533e95977d478d387b/293e0/INSTAR_MQTT_with_Athom_Homey_01.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cfbd0d8894204b533e95977d478d387b/81c8e/INSTAR_MQTT_with_Athom_Homey_01.png 230w", "/en/static/cfbd0d8894204b533e95977d478d387b/08a84/INSTAR_MQTT_with_Athom_Homey_01.png 460w", "/en/static/cfbd0d8894204b533e95977d478d387b/c0255/INSTAR_MQTT_with_Athom_Homey_01.png 920w", "/en/static/cfbd0d8894204b533e95977d478d387b/b1001/INSTAR_MQTT_with_Athom_Homey_01.png 1380w", "/en/static/cfbd0d8894204b533e95977d478d387b/aa440/INSTAR_MQTT_with_Athom_Homey_01.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cfbd0d8894204b533e95977d478d387b/c0255/INSTAR_MQTT_with_Athom_Homey_01.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Start by launching your Homey App for `}<a parentName="p" {...{
            "href": "https://apps.apple.com/us/app/homey/id1435800024"
          }}>{`iOS`}</a>{` or `}<a parentName="p" {...{
            "href": "https://play.google.com/store/apps/details?id=app.homey&hl=en_US"
          }}>{`Android`}</a>{` and switch to the `}<strong parentName="p">{`More`}</strong>{` App.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Open the Apps category.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`And click on the `}<strong parentName="p">{`+`}</strong>{` icon in the top right to add a new app.`}</p>
      </li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/de3d07bb9395f50841507ea181dad730/aa440/INSTAR_MQTT_with_Athom_Homey_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDElEQVQoz1XSzU5TQRTA8bvHhpT2fna+7r0tFSuY6MKgwaVu9CUoEp/EuBEMSuUNTEhYS6Im+AqEFQaUhaVNNS1UpKG9/ZveEoiTnMXkZH7nnJmxXiwv8by6xNLi4jiqVZarVZ49eUTJSCLfwXg22s2jnBxx4DA3rSiXYlTgIefvIk0B23ZYmPOwuo0Gvc4J/dMuydkZ5+0OF60Wr1ZfImYriOwUU5kMuUyGqRsZchMThMLFDQr4no94+pigGDGZdbhXzmEdfT/guF7nV6tFs9EgYbxqG2tMug6FIMBx3TRsxyHv2MzogFgIYimpKMW0FHh5n4UZG+vL50983N5md3eXD1tb/N3bg2aT9dobctksURShlEJKiRQCIQrcCgWxkCk4UyxSUhLfvgSTwUXa0UmnQ38wYHB6mu7fr6+Sz+evQDUCpUzBUiSRSiOkpDAqIgU5V3C/4mIdHnxjf3+fna87bG5u0uv3x+Db1+RtmziO0VpfdykF5VhhtEGPCqXFBLYnmR+B9eOf1JtN/nS7tNtter3eNXjZ4X+gEJSLmjAMMVqnOa0Vjq94cNvD4schHB0xAIZJQpKMn2Xj3Ur6FeK4iDHmCh2NfrNkCKMIY8I0Z4zGDTQPZ32sYec3w3Y7RQbDhPOL8Z3W1lbwPJfpUpEoCgnD8UGjFZVySDGOiaOIKAyJQkMgDAt3Av4B7at3FSlmqZQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de3d07bb9395f50841507ea181dad730/e4706/INSTAR_MQTT_with_Athom_Homey_02.avif 230w", "/en/static/de3d07bb9395f50841507ea181dad730/d1af7/INSTAR_MQTT_with_Athom_Homey_02.avif 460w", "/en/static/de3d07bb9395f50841507ea181dad730/7f308/INSTAR_MQTT_with_Athom_Homey_02.avif 920w", "/en/static/de3d07bb9395f50841507ea181dad730/e1c99/INSTAR_MQTT_with_Athom_Homey_02.avif 1380w", "/en/static/de3d07bb9395f50841507ea181dad730/2b305/INSTAR_MQTT_with_Athom_Homey_02.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/de3d07bb9395f50841507ea181dad730/a0b58/INSTAR_MQTT_with_Athom_Homey_02.webp 230w", "/en/static/de3d07bb9395f50841507ea181dad730/bc10c/INSTAR_MQTT_with_Athom_Homey_02.webp 460w", "/en/static/de3d07bb9395f50841507ea181dad730/966d8/INSTAR_MQTT_with_Athom_Homey_02.webp 920w", "/en/static/de3d07bb9395f50841507ea181dad730/445df/INSTAR_MQTT_with_Athom_Homey_02.webp 1380w", "/en/static/de3d07bb9395f50841507ea181dad730/293e0/INSTAR_MQTT_with_Athom_Homey_02.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/de3d07bb9395f50841507ea181dad730/81c8e/INSTAR_MQTT_with_Athom_Homey_02.png 230w", "/en/static/de3d07bb9395f50841507ea181dad730/08a84/INSTAR_MQTT_with_Athom_Homey_02.png 460w", "/en/static/de3d07bb9395f50841507ea181dad730/c0255/INSTAR_MQTT_with_Athom_Homey_02.png 920w", "/en/static/de3d07bb9395f50841507ea181dad730/b1001/INSTAR_MQTT_with_Athom_Homey_02.png 1380w", "/en/static/de3d07bb9395f50841507ea181dad730/aa440/INSTAR_MQTT_with_Athom_Homey_02.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/de3d07bb9395f50841507ea181dad730/c0255/INSTAR_MQTT_with_Athom_Homey_02.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Type `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`mqtt`}</code>{` into the search field and select the `}<strong parentName="li">{`MQTT Client`}</strong>{`. I am also installing the MQTT Hub - `}<em parentName="li">{`this is optional`}</em>{`. The Hub App publishes Homey state variables through MQTT which comes in handy if you also use another smarthome solution that you want to interconnect with your Homey.`}</li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Once installed, click on the `}<strong parentName="li">{`MQTT Client`}</strong>{` inside the Apps category.`}</li>
    </ol>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`Configure App`}</strong>{` to connect the client to the INSTAR MQTT Server.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/02408685c81dbd20e0fa5111e249972f/aa440/INSTAR_MQTT_with_Athom_Homey_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB4klEQVQozy2Qa2vTUByH89pVtyxJG9ckJ5d2w+EL0Q/gC7+aINTpWGWivle8DouKgjCZDPQD6JhpUk5u7srS9KS5npMjaX143v5+/wtzeuShSVjhgpR5maeU0u3Ne/KKqMsrys3rynpXFgWBW9r7+rHIpmWekCLDeYIJzU9/MRfnZ1E0SZMaNEGU0v5mr7G42G6Jyp3b0q0botC8tLDwbfcLpTTLc1ziLMswpXn4hxn7Xj6OCEJVHJdRRCl91rvbYtkuAOsArMmyLkl8o7E/CxNCMC5pReqi6JBBUZgkKS5LWlUYY0rp060NkWVXAbhmGGuaprcl4crl3c8fEIrD8AIhhCbRZFokx7+ZNJ0WRVGWxZz65v6DZY5TNU2S5bYkyYqyxLI7r59DCE3ThBDatmXajnfwnYlRmCRobhzXa29v9Xie13UdKDVAUTiWff/2hW3bpmn6vu840IZBcLjPROPzJI3TmfPw4/6GIAiGoauqCgBQgcpxy58Gb/wgsCzLdV3XgW5wcjz8wRR5VtH/EEJmk+83m61Op6OqGgCqqmk8zw92Xnme7zjO3yAIfM8/OjuxfjK0wrSq5pKyftiTRw+viq3V1a6h67qmGYbeagqDdy8D37esoQPhaGQNR653sPcPlUNk3oGzMZYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02408685c81dbd20e0fa5111e249972f/e4706/INSTAR_MQTT_with_Athom_Homey_03.avif 230w", "/en/static/02408685c81dbd20e0fa5111e249972f/d1af7/INSTAR_MQTT_with_Athom_Homey_03.avif 460w", "/en/static/02408685c81dbd20e0fa5111e249972f/7f308/INSTAR_MQTT_with_Athom_Homey_03.avif 920w", "/en/static/02408685c81dbd20e0fa5111e249972f/e1c99/INSTAR_MQTT_with_Athom_Homey_03.avif 1380w", "/en/static/02408685c81dbd20e0fa5111e249972f/2b305/INSTAR_MQTT_with_Athom_Homey_03.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/02408685c81dbd20e0fa5111e249972f/a0b58/INSTAR_MQTT_with_Athom_Homey_03.webp 230w", "/en/static/02408685c81dbd20e0fa5111e249972f/bc10c/INSTAR_MQTT_with_Athom_Homey_03.webp 460w", "/en/static/02408685c81dbd20e0fa5111e249972f/966d8/INSTAR_MQTT_with_Athom_Homey_03.webp 920w", "/en/static/02408685c81dbd20e0fa5111e249972f/445df/INSTAR_MQTT_with_Athom_Homey_03.webp 1380w", "/en/static/02408685c81dbd20e0fa5111e249972f/293e0/INSTAR_MQTT_with_Athom_Homey_03.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/02408685c81dbd20e0fa5111e249972f/81c8e/INSTAR_MQTT_with_Athom_Homey_03.png 230w", "/en/static/02408685c81dbd20e0fa5111e249972f/08a84/INSTAR_MQTT_with_Athom_Homey_03.png 460w", "/en/static/02408685c81dbd20e0fa5111e249972f/c0255/INSTAR_MQTT_with_Athom_Homey_03.png 920w", "/en/static/02408685c81dbd20e0fa5111e249972f/b1001/INSTAR_MQTT_with_Athom_Homey_03.png 1380w", "/en/static/02408685c81dbd20e0fa5111e249972f/aa440/INSTAR_MQTT_with_Athom_Homey_03.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/02408685c81dbd20e0fa5111e249972f/c0255/INSTAR_MQTT_with_Athom_Homey_03.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`Start by entering the IP address of your camera (that is running your MQTT broker) - e.g. `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`192.168.2.116`}</code>{`. We are going to use the Port `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`1883`}</code>{` that allows us to connect to the MQTT broker without the SSL encryption (an encrypted connection can be established via the port `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`8883`}</code>{` but requires a custom SSL certificate which I could not find out how to upload). The `}<strong parentName="li">{`username`}</strong>{` and `}<strong parentName="li">{`password`}</strong>{` for the MQTT broker is the one you set in the `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
        }}>{`MQTT Menu`}</a>{`.`}</li>
    </ol>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`If you installed the MQTT Hub earlier - `}<em parentName="li">{`which is optional`}</em>{` - enter it and click on `}<strong parentName="li">{`Configure app`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Here you can set which information Homey should publish to our MQTT broker for use with other smarthome components.`}</li>
    </ol>
    <h2 {...{
      "id": "sending-a-mqtt-command-to-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sending-a-mqtt-command-to-your-camera",
        "aria-label": "sending a mqtt command to your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending a MQTT Command to your Camera`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2e305ca29a995d9015edd197f30ed2d0/aa440/INSTAR_MQTT_with_Athom_Homey_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByElEQVQoz23OzWsTQRzG8TmYuJpsa3yrMQ0kRAsWvHjyUBBFpRC8KIoeBIXS0iIVqlGrgnjRQw4erJZYQ8W0/4Mg5pSzoAetV9G22yWbnbffzOzOzoppaxV8+Fwf+KLDN18MjNeOjLw+em3+2NWFwXNvX75phRJLAaES64AT6i132q4UnFOsgEmlYeUT6r81k598VZioHxqZH7zeKF5oVOeaOoDIxGZTHMcUtzs+IYxjwijjVBrufEEH7j47OPW8eGOmOF4bGK3nL89V6+8DoN3L1rDneF4HQEoZqCBQUSzdb2hPtVZ4NFsee5ybbpQqi9mxxacL7yTzon/fvvOz7biCcSBUEKKUgeXPaH+53HtyuDR8vv/spcKZK9mhi0+qsyEIY/7uNmTlu7fqCEolZZIQCZr9+IjKp06fOD6USaZ70PbMtlQSoTuTE4D90MeakA2+L3Gbg+RcAEjgACqWa0sIE+yuOrl8CSV6dth9CFkPK/c4CAEi0ibSUZeWHINQG0BAEAt3CbVareaHZrYvl0jadnofQon7lWlQGrjQ2myK/n+27Ixl77ZSvVZq1870XoQSUw9uq1jrIAz1n4WC+b+D13WzxdrXX+b5jq6y5La4AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e305ca29a995d9015edd197f30ed2d0/e4706/INSTAR_MQTT_with_Athom_Homey_04.avif 230w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/d1af7/INSTAR_MQTT_with_Athom_Homey_04.avif 460w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/7f308/INSTAR_MQTT_with_Athom_Homey_04.avif 920w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/e1c99/INSTAR_MQTT_with_Athom_Homey_04.avif 1380w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/2b305/INSTAR_MQTT_with_Athom_Homey_04.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2e305ca29a995d9015edd197f30ed2d0/a0b58/INSTAR_MQTT_with_Athom_Homey_04.webp 230w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/bc10c/INSTAR_MQTT_with_Athom_Homey_04.webp 460w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/966d8/INSTAR_MQTT_with_Athom_Homey_04.webp 920w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/445df/INSTAR_MQTT_with_Athom_Homey_04.webp 1380w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/293e0/INSTAR_MQTT_with_Athom_Homey_04.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2e305ca29a995d9015edd197f30ed2d0/81c8e/INSTAR_MQTT_with_Athom_Homey_04.png 230w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/08a84/INSTAR_MQTT_with_Athom_Homey_04.png 460w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/c0255/INSTAR_MQTT_with_Athom_Homey_04.png 920w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/b1001/INSTAR_MQTT_with_Athom_Homey_04.png 1380w", "/en/static/2e305ca29a995d9015edd197f30ed2d0/aa440/INSTAR_MQTT_with_Athom_Homey_04.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2e305ca29a995d9015edd197f30ed2d0/c0255/INSTAR_MQTT_with_Athom_Homey_04.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol>
      <li parentName="ol">{`Now switch to the `}<strong parentName="li">{`Devices`}</strong>{` tab and check if you are able to add virtual devices. If you can't find them, the are still hidden as `}<em parentName="li">{`experimental features`}</em>{`.`}</li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Switch to the `}<strong parentName="li">{`More`}</strong>{` tab and enter the `}<strong parentName="li">{`Settings`}</strong>{` menu. Click on `}<strong parentName="li">{`Experiments`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Activate `}<strong parentName="li">{`Virtual Devices`}</strong>{`.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d99278993d0a5d638adb17e13e8e0c8f/aa440/INSTAR_MQTT_with_Athom_Homey_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB6UlEQVQoz22PTWsTURSGz+Te+Z47N52xrSNTXNSFaxduVFLQQmsbYom1oqVW0IW/wKUUPxZCiEVQbEhoaBvEgFBNN66EKipZpAmImQqJ+IGgmdY2YlAzcyUjbqQPhwPv4jkvBzQtDCBwHM+BiEASOLWX6gKPeAQ4GB6ByHNHDh6YOBGfiI3Fj8dGBgaHBkYuRCNw5uxUfHxyeOz0aHR88Fj01Mmp/f37eEGQZEUQJVESZUUBgLnbSdb2fnzb+tls/vreZL/Z59ersLnpbm1vb5RKrVbL3XAZY5cunieE7AmwLMu2bUK0hfkUY6zdbjPGfM9jjDXqRaiUy6W18oNr15cfFbILS8+ev4iNDhFCurt7ujoYprkLYzyfubeD7Da+fPj4ydjbD1hBMuVEYhrhPts2TBNCHIcw5kUAyKTu7iBXq2/WKhXT6gNRxWoYy+Ew1SjVZbnzKgAghAAgnbrzv1wrAu3dTXosrFGkUFHrAk6MRA7n8/lsNpvukMnl7icSyZXHD//Kvu97XufE13oRQqoKsopUHSm6oBkAaPrc5L8S3/d9xpjrNoovV1mQgx00L6dBoHqI6JjoWKMCocBJR4cPzT29mShcSRZmbq3MzD65eiN3eXExVau/qzqOs77uONW3tfevlmb/AKxOANvCTFB6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d99278993d0a5d638adb17e13e8e0c8f/e4706/INSTAR_MQTT_with_Athom_Homey_05.avif 230w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/d1af7/INSTAR_MQTT_with_Athom_Homey_05.avif 460w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/7f308/INSTAR_MQTT_with_Athom_Homey_05.avif 920w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/e1c99/INSTAR_MQTT_with_Athom_Homey_05.avif 1380w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/2b305/INSTAR_MQTT_with_Athom_Homey_05.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d99278993d0a5d638adb17e13e8e0c8f/a0b58/INSTAR_MQTT_with_Athom_Homey_05.webp 230w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/bc10c/INSTAR_MQTT_with_Athom_Homey_05.webp 460w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/966d8/INSTAR_MQTT_with_Athom_Homey_05.webp 920w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/445df/INSTAR_MQTT_with_Athom_Homey_05.webp 1380w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/293e0/INSTAR_MQTT_with_Athom_Homey_05.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d99278993d0a5d638adb17e13e8e0c8f/81c8e/INSTAR_MQTT_with_Athom_Homey_05.png 230w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/08a84/INSTAR_MQTT_with_Athom_Homey_05.png 460w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/c0255/INSTAR_MQTT_with_Athom_Homey_05.png 920w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/b1001/INSTAR_MQTT_with_Athom_Homey_05.png 1380w", "/en/static/d99278993d0a5d638adb17e13e8e0c8f/aa440/INSTAR_MQTT_with_Athom_Homey_05.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d99278993d0a5d638adb17e13e8e0c8f/c0255/INSTAR_MQTT_with_Athom_Homey_05.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Back to `}<strong parentName="li">{`Devices`}</strong>{` you should now be able to add a `}<strong parentName="li">{`Virtual Button`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol">{`Click on `}<strong parentName="li">{`Install`}</strong>{` to add the button to your devices.`}</li>
    </ol>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click `}<strong parentName="li">{`Next`}</strong>{` to finish the installation.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/379d0cb62de99cd4b7db0862285dc903/aa440/INSTAR_MQTT_with_Athom_Homey_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB+UlEQVQoz2PIrt9RWHWgNHd3V+nxooAjDV7X4m3KVdSklRXlZaUkJKUltKXE3fm4d21e/fvTh58f3//+/PH35w+/vn7/+vA0w9sPHz9//P7m1Yf///9X5xxPdj7vb1YsIi4gJSUjKSUlISkpJSXNw8W1f9vG//////r85c+Xr3++fv394++XB2cZDl16dOTc4wPH7l679jw5bXeQ5xEPq3xJaRFZWTkZGRkpKSkZGRkeXp5DB/b8////2/cfP758/fnjx8+//3++vcWgnjpHM3GRTtRio/AVZoGr3YL22ltlSUuLysnJS4KBnJwcHx/vwX27//////nLt2/ff3379uPrz//fX91gUMqeqZw6Tz1xoVb0Et2wpWahW8xt0uVkxeXlFURERMTExOTl5fn5+Q7th2v++e3b92+//n9/fYNBvnCqQs5s5fS5IP2xi/Si1xnaJyvIScorKCooKMjJycnLywvw82PXLFU2SaZwunzuLOWMuWpJ87QSV+k7JSnKSSkoKiooyMvLyysoKAgJCmDXLFrTL1Y+WapomnzeTMWM2arpy3TdEhTlpRWVlBUUFOXlFRQVFYWEhA7t34NFs0Rrr2jdBIXaaTKlUxULpqvkL9L1jFNVkFVRUVFRVlJWUlRRVhIVET4Msfnz12/ffnz7+u0bKMCuAwCRexe9GMtTuwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/379d0cb62de99cd4b7db0862285dc903/e4706/INSTAR_MQTT_with_Athom_Homey_06.avif 230w", "/en/static/379d0cb62de99cd4b7db0862285dc903/d1af7/INSTAR_MQTT_with_Athom_Homey_06.avif 460w", "/en/static/379d0cb62de99cd4b7db0862285dc903/7f308/INSTAR_MQTT_with_Athom_Homey_06.avif 920w", "/en/static/379d0cb62de99cd4b7db0862285dc903/e1c99/INSTAR_MQTT_with_Athom_Homey_06.avif 1380w", "/en/static/379d0cb62de99cd4b7db0862285dc903/2b305/INSTAR_MQTT_with_Athom_Homey_06.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/379d0cb62de99cd4b7db0862285dc903/a0b58/INSTAR_MQTT_with_Athom_Homey_06.webp 230w", "/en/static/379d0cb62de99cd4b7db0862285dc903/bc10c/INSTAR_MQTT_with_Athom_Homey_06.webp 460w", "/en/static/379d0cb62de99cd4b7db0862285dc903/966d8/INSTAR_MQTT_with_Athom_Homey_06.webp 920w", "/en/static/379d0cb62de99cd4b7db0862285dc903/445df/INSTAR_MQTT_with_Athom_Homey_06.webp 1380w", "/en/static/379d0cb62de99cd4b7db0862285dc903/293e0/INSTAR_MQTT_with_Athom_Homey_06.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/379d0cb62de99cd4b7db0862285dc903/81c8e/INSTAR_MQTT_with_Athom_Homey_06.png 230w", "/en/static/379d0cb62de99cd4b7db0862285dc903/08a84/INSTAR_MQTT_with_Athom_Homey_06.png 460w", "/en/static/379d0cb62de99cd4b7db0862285dc903/c0255/INSTAR_MQTT_with_Athom_Homey_06.png 920w", "/en/static/379d0cb62de99cd4b7db0862285dc903/b1001/INSTAR_MQTT_with_Athom_Homey_06.png 1380w", "/en/static/379d0cb62de99cd4b7db0862285dc903/aa440/INSTAR_MQTT_with_Athom_Homey_06.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/379d0cb62de99cd4b7db0862285dc903/c0255/INSTAR_MQTT_with_Athom_Homey_06.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 7
    }}>
      <li parentName="ol">{`You can `}<em parentName="li">{`click and hold`}</em>{` the virtual button to rename it.`}</li>
    </ol>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Click on the cog wheel icon to enter the settings menu.`}</li>
    </ol>
    <ol {...{
      "start": 9
    }}>
      <li parentName="ol">{`Add a name for your virtual button and add it to a zone.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/78a02360cd5a63e2f54c968ca99805c0/aa440/INSTAR_MQTT_with_Athom_Homey_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACMElEQVQozwElAtr9AGt/t3B6wXNxwIl5zHJSx39GzmpYuDtaqUZQsFROt2BMwnhEu506n5ZPzpdH1JVA1JdA1ppA1pk836xNvQDu7/Dy9Prs7vT///95b8xjP8ZWVLMoWKQzU6w+ULJNTblbSsFoSMuEQcCEOcOGO8WKPMeMO8aKNs6eRbAAw9Lhw9Dmvcni0tjsYWi/UkW8TVmsJ2ChLVinMVSpO1CvS063WEy9e0nJgUXYgkTYhUPZiETZhkDim03AACdlnClhoixbpDFXqTZTrUZNtkFcpiNlnipfoy1apjFWqTpRr0ZPtXFKwXhFz3hFz3tE0X5E0XxA2pNOuQAia5kjZZ4oYKEqW6QtV6c2Ua02YZ8fa5onZaBDc61Aaq0vVag1U6tmTL1vR8xwRstyRsx2R81zQ9WLULUAH3GVIGybI2edJ2GgKl2jLleoLmWaHHGXIWqbLGuhLWWjKlukLFemXE+3Z0jHZ0nGaUfIbUjJakTRhFGxABp2khtxlyBtmR9qnyJloixcoylrlhh3lB1wlx9qmiJmnSdioSheolRRsl5Kwl5LwWBKw2RKw2FGzHxTrQAVfI8Xd5MVdJkzZIs2Xo0hZKMncJITfY8YdZQccZcfbZojaJ0kZJ5MVK5SSrxUTL1XS75aTL5XSMZxUqcAGIWNFn6PHH+ZMWuHN2aKIGygJHaNFYWNGn6SHHqVHnSWI2+aImqZWmuxVVa7Sky2T064UU24Uk7Bf2mqWef7J5TvwMMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/78a02360cd5a63e2f54c968ca99805c0/e4706/INSTAR_MQTT_with_Athom_Homey_07.avif 230w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/d1af7/INSTAR_MQTT_with_Athom_Homey_07.avif 460w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/7f308/INSTAR_MQTT_with_Athom_Homey_07.avif 920w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/e1c99/INSTAR_MQTT_with_Athom_Homey_07.avif 1380w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/2b305/INSTAR_MQTT_with_Athom_Homey_07.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/78a02360cd5a63e2f54c968ca99805c0/a0b58/INSTAR_MQTT_with_Athom_Homey_07.webp 230w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/bc10c/INSTAR_MQTT_with_Athom_Homey_07.webp 460w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/966d8/INSTAR_MQTT_with_Athom_Homey_07.webp 920w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/445df/INSTAR_MQTT_with_Athom_Homey_07.webp 1380w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/293e0/INSTAR_MQTT_with_Athom_Homey_07.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/78a02360cd5a63e2f54c968ca99805c0/81c8e/INSTAR_MQTT_with_Athom_Homey_07.png 230w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/08a84/INSTAR_MQTT_with_Athom_Homey_07.png 460w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/c0255/INSTAR_MQTT_with_Athom_Homey_07.png 920w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/b1001/INSTAR_MQTT_with_Athom_Homey_07.png 1380w", "/en/static/78a02360cd5a63e2f54c968ca99805c0/aa440/INSTAR_MQTT_with_Athom_Homey_07.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/78a02360cd5a63e2f54c968ca99805c0/c0255/INSTAR_MQTT_with_Athom_Homey_07.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Now we need to add some logic to made this button work. This can be done in the `}<strong parentName="li">{`Flows`}</strong>{` tab.`}</li>
    </ol>
    <ol {...{
      "start": 11
    }}>
      <li parentName="ol">{`Click on the `}<strong parentName="li">{`+`}</strong>{` icon in the top right to add a new flow.`}</li>
    </ol>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`In the `}<strong parentName="li">{`Create Flow`}</strong>{` menu click on `}<strong parentName="li">{`Add card`}</strong>{` in the `}<em parentName="li">{`When condition`}</em>{` to assign an event that should trigger your flow.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/aa440/INSTAR_MQTT_with_Athom_Homey_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABrUlEQVQoz2WP30tTUQDHz98U9OCDKGpevZswRZsMSSFIDHzQlj5kqLG3QSBoCKFGJPgU6ZBiDSm3cMgUJq4XGc6p5bYzb957fp977okILs0+fF4/fPkCq3bl3FiuYJJTySmjGFXK15fnwrYUthW2XWQrWv80/331/snbweJ6uLQRLm0MVD6OfAPWeVlp7f1Be57WWoubulWp2rDOHSwQ4Q7xOE6/KW69PEstX6ZXYGalllnD+0uHAMKfuhHBEGGScomZwEw4RLhavh4/jt7JzzYXZpsKL5qO5+4WX91LgWqtpFRjTB1MGKH8rwhzpeXy0/xEy8FMd37GOJozjp53nCyGkgBWrpTXgKA2JvyfmHlaLkQPHzVnJ7tzk525KePgSXshHtoGVQg9T7uu8uXE9ks/jk/tD7Xsjpl7Y0Z2vCv7uCMf690EZ6XT/z47t5a1lrHpvb62neGe9LCZeWhmRozcs/73AF5cCERcTH25fX1rWWt3fvpLoDURCSYjZvKB+TnSuRvtfQdqP8rCQRJjX/YLEsJ8EaJKiYX411DwQ19wqz+YCAcSg4FUbHT9NzdCupUN4oM3AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/e4706/INSTAR_MQTT_with_Athom_Homey_08.avif 230w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/d1af7/INSTAR_MQTT_with_Athom_Homey_08.avif 460w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/7f308/INSTAR_MQTT_with_Athom_Homey_08.avif 920w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/e1c99/INSTAR_MQTT_with_Athom_Homey_08.avif 1380w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/2b305/INSTAR_MQTT_with_Athom_Homey_08.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/a0b58/INSTAR_MQTT_with_Athom_Homey_08.webp 230w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/bc10c/INSTAR_MQTT_with_Athom_Homey_08.webp 460w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/966d8/INSTAR_MQTT_with_Athom_Homey_08.webp 920w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/445df/INSTAR_MQTT_with_Athom_Homey_08.webp 1380w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/293e0/INSTAR_MQTT_with_Athom_Homey_08.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/81c8e/INSTAR_MQTT_with_Athom_Homey_08.png 230w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/08a84/INSTAR_MQTT_with_Athom_Homey_08.png 460w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/c0255/INSTAR_MQTT_with_Athom_Homey_08.png 920w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/b1001/INSTAR_MQTT_with_Athom_Homey_08.png 1380w", "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/aa440/INSTAR_MQTT_with_Athom_Homey_08.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1eddc6cc9ad39c6f59a523a5d4d00bd6/c0255/INSTAR_MQTT_with_Athom_Homey_08.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 13
    }}>
      <li parentName="ol">{`Search for your virtual button under `}<strong parentName="li">{`Devices`}</strong>{` and select it.`}</li>
    </ol>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`The button only has this one trigger - `}<em parentName="li">{`When button has bee pressed`}</em>{`. Select it and confirm.`}</li>
    </ol>
    <ol {...{
      "start": 15
    }}>
      <li parentName="ol">{`Now swipe up the `}<strong parentName="li">{`Then`}</strong>{` section of our flow to define what Homey should do when the flow was triggered.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/bfa794d0d06a89c08ee20701db00aea6/aa440/INSTAR_MQTT_with_Athom_Homey_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAABkUlEQVQoz3XOSUsjURSG4QJbgxpLFBSHrHrjn3PhIoFAnGJu3arK0BWTH+DWFlpQ2gEXbl25VrGhcQDR0LFrOufeGu6RSFRo7MO7OJsHPm2BtXNrrZlSc67UzJVakwWntn8kva7n/k0iEUuMJQoIHu6vn25/x76rwE8DNwEp78+1XJFP5Y3Zgjmft+bztr7IjK1tCiElUqlSipQiIvrVuXu4ue0+PqLrpgBpRKpzqc2WKtNFNtPLmCtyfam8vr1LUr6S91MCvFDIEGUAAoXAmLBzpY3z2pjR0FlDr3ybZM7gSr2w8z0KPEWkSH3g0AOU/QAhIuxcarplZk1rjNu6UZ3g9YFVc+XwR4J+0tv8fqkI3U9wtsZHqtZo1cra9rhd1cps+fhngkImaZyqt/6DRx2WafDhOh+u8Wzd1IxK/mAHu3+iMEwA+gWB8J4Bo3/xF2dtsMmGmizjGCOOqfHV5ZM9AhG4Hnp+v97/Gf661Rhol4fa5UxrI7O5obf55tkpJeSD+NiJAvxnANEvBJCETxcv/8OerQKtoPMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfa794d0d06a89c08ee20701db00aea6/e4706/INSTAR_MQTT_with_Athom_Homey_09.avif 230w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/d1af7/INSTAR_MQTT_with_Athom_Homey_09.avif 460w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/7f308/INSTAR_MQTT_with_Athom_Homey_09.avif 920w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/e1c99/INSTAR_MQTT_with_Athom_Homey_09.avif 1380w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/2b305/INSTAR_MQTT_with_Athom_Homey_09.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/bfa794d0d06a89c08ee20701db00aea6/a0b58/INSTAR_MQTT_with_Athom_Homey_09.webp 230w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/bc10c/INSTAR_MQTT_with_Athom_Homey_09.webp 460w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/966d8/INSTAR_MQTT_with_Athom_Homey_09.webp 920w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/445df/INSTAR_MQTT_with_Athom_Homey_09.webp 1380w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/293e0/INSTAR_MQTT_with_Athom_Homey_09.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/bfa794d0d06a89c08ee20701db00aea6/81c8e/INSTAR_MQTT_with_Athom_Homey_09.png 230w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/08a84/INSTAR_MQTT_with_Athom_Homey_09.png 460w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/c0255/INSTAR_MQTT_with_Athom_Homey_09.png 920w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/b1001/INSTAR_MQTT_with_Athom_Homey_09.png 1380w", "/en/static/bfa794d0d06a89c08ee20701db00aea6/aa440/INSTAR_MQTT_with_Athom_Homey_09.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/bfa794d0d06a89c08ee20701db00aea6/c0255/INSTAR_MQTT_with_Athom_Homey_09.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 16
    }}>
      <li parentName="ol">{`Now click on `}<strong parentName="li">{`Add card`}</strong>{` to add the `}<strong parentName="li">{`Then`}</strong>{` condition for our flow.`}</li>
    </ol>
    <ol {...{
      "start": 17
    }}>
      <li parentName="ol">{`Scroll down to the `}<strong parentName="li">{`Apps`}</strong>{` section and select the `}<strong parentName="li">{`MQTT Client`}</strong>{`.`}</li>
    </ol>
    <ol {...{
      "start": 18
    }}>
      <li parentName="ol">{`And choose to `}<strong parentName="li">{`Publish a message`}</strong>{` to the INSTAR MQTT server.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/aa440/INSTAR_MQTT_with_Athom_Homey_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAAsTAAALEwEAmpwYAAACHElEQVQoz2N49fr5j+9f//799efPz1+/f/z//3vynr1i1X2ydRMlqvolKydIVUyQrZ7okzs/LGF9VNSm1NAdxcH7av3OzInexPDm1Ytfnz7//fb977fvv758+//379Rde3hL26Rr+qRr+qSqeqXKeyUqehxz57gmrHGPWRscuSUxfFdm0InuyHUM3x49/PXm7Z83b/68efPr9ev/H96v2rcna9HyuuVrqpaublqzuXXdtobVm6zy5qhELNEJW24YuMbOf6OH54GC4BUMnz59+vb9589fv3/++v3956//f//17N/D3dwp3TlBoq1PpmOibPtkmY5JmgWT1ZPnaSYs0I5ebBC21CRwU3zkbIZv375//f7zGxh9/vbj/9//HQd2MzQ08bV3cbd1cbd08jSDSKO8aZZxS2wil7uFrvEL2hTse6AmchlI8zc0zQd3MbQ28PV0cne1c3d08HV0sTa31i7Yeuniuw2b7146/2bb3FtZlkd7g3ZiaP7zv+XIboauBu6+Ns6eNs7uNt6eDoa25uWnzvz////+sw////+/vvtCgsnCsvANKJq/fP3+7///uVtW8VflskztYO9vYe9rZevrUCvL9W2Yndq7L7J+e2brvojifRbee7LCFzN8+/r127ef3779+Pbtx5ev3/7+/b/swBalnmrGac3sk5rYJzUxT2iy66vXLeyTiJmlGD1XMWSBVtAiw8D1UVGzAKpHTmGjdis+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/e4706/INSTAR_MQTT_with_Athom_Homey_10.avif 230w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/d1af7/INSTAR_MQTT_with_Athom_Homey_10.avif 460w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/7f308/INSTAR_MQTT_with_Athom_Homey_10.avif 920w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/e1c99/INSTAR_MQTT_with_Athom_Homey_10.avif 1380w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/2b305/INSTAR_MQTT_with_Athom_Homey_10.avif 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/a0b58/INSTAR_MQTT_with_Athom_Homey_10.webp 230w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/bc10c/INSTAR_MQTT_with_Athom_Homey_10.webp 460w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/966d8/INSTAR_MQTT_with_Athom_Homey_10.webp 920w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/445df/INSTAR_MQTT_with_Athom_Homey_10.webp 1380w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/293e0/INSTAR_MQTT_with_Athom_Homey_10.webp 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/81c8e/INSTAR_MQTT_with_Athom_Homey_10.png 230w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/08a84/INSTAR_MQTT_with_Athom_Homey_10.png 460w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/c0255/INSTAR_MQTT_with_Athom_Homey_10.png 920w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/b1001/INSTAR_MQTT_with_Athom_Homey_10.png 1380w", "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/aa440/INSTAR_MQTT_with_Athom_Homey_10.png 1500w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/53bfdf2d6bebd8bac569b28fb66db4f3/c0255/INSTAR_MQTT_with_Athom_Homey_10.png",
              "alt": "INSTAR MQTT Broker und Athom Homey",
              "title": "INSTAR MQTT Broker und Athom Homey",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ol {...{
      "start": 19
    }}>
      <li parentName="ol">{`Now you can select a MQTT Topic from the `}<a parentName="li" {...{
          "href": "/en/Advanced_User/INSTAR_MQTT_Broker/"
        }}>{`INSTAR MQTT API`}</a>{` that you want to update every time the button is pressed. Here I choose `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`alarm/pushalarm`}</code>{` with a value of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"1"}`}</code>{` to trigger an alarm on my camera. Note that if you have more than 1 camera connected to the MQTT server, you either have to add the MAC address in front of the MQTT topic to address a specific camera. Or add `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`all`}</code>{` - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`all/alarm/pushalarm`}</code>{` - to address all connected cameras. By adding prefix `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`local`}</code>{`, you only address the camera that is running the MQTT broker.`}</li>
    </ol>
    <ol {...{
      "start": 20
    }}>
      <li parentName="ol">{`After you confirmed your edit click `}<strong parentName="li">{`Test`}</strong>{` to verify that everything is working - your cameras `}<a parentName="li" {...{
          "href": "/en/Web_User_Interface/1080p_Series/System/Log/"
        }}>{`System Log`}</a>{` should show an `}<strong parentName="li">{`Audio Alarm`}</strong>{` (Note that the Audio Alarm has a `}<strong parentName="li">{`cool-down of 60s`}</strong>{` - so even if you hit the virtual button serveral times, you only get 1 alarm per minute). Now you can click on save to exit the setup window.`}</li>
    </ol>
    <ol {...{
      "start": 21
    }}>
      <li parentName="ol">{`You now have a working button in the `}<strong parentName="li">{`Devices`}</strong>{` window that can trigger an alarm on your camera. Try to add another button with the MQTT topic `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`features/ptz/preset`}</code>{` and a value of `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"0"}`}</code>{` - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`{"val":"7"}`}</code>{` to have your camera pan&tilt between its preset positions.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      